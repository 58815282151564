<template>
  <div class="schArt" >
    <div class="content" style ="min-height:92.9vh">
    <Head></Head>
    <div v-show="isShow" class="main"  >
            <div class="queInfo"

             v-for="(item,index) in schArts" :key="index"
              @click="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})"
             >
            <div class="top">
            <div class="title">
                <h3>{{item.title}} <span v-show="item.isdown===0?false:true">已下架</span></h3>
                <!-- <p>￥<span>{{item.price}}</span></p> -->
            </div>
            <div class="data">
                <p><span>发表时间:</span>{{item.pubdata | dataFormat}}</p>
            </div>
        </div>
        <!-- <div class="content">
            <p v-html="item.content"></p>
        </div> -->
        <!-- <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="item.content"></div>
          </div>
          </div>
      </div> -->
        <div class="info">
            <div class="views">
                <ul>
                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>

                </ul>
            </div>
            <div class="edit">
                <ul>
                    <li @click="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})">查看详情</li>

                </ul>

            </div>
        </div>
        </div>
    </div>

    <el-empty class="el-empty" v-show="!isShow" :image-size="200"></el-empty>
    </div>
 <!-- 分页
   <div class="page">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="1000">
      </el-pagination>
   </div> -->
   <router-view @showSchInfo="showSch"></router-view>
   <Foot ></Foot>
  </div>
</template>

<script>
import head from '../head.vue'
import { getSchArtApi } from '../../API/schArt.js'
import Foot from '../../views/foot.vue'
export default {
  name: 'schArt',
  data () {
    return {
      schArts: [],
      isShow: false
    }
  },
  methods: {
    // 获取所有文章
    showSch (e) {
      // console.log(e)
    },
    async getSchArts () {
      const { data: res } = await getSchArtApi()
      // console.log(res)
      if (res.status === 0) {
        res.data.map((item, index, arr) => {
          // 已发布列表(包含既未下架也未删除)
          if (item.isdelete === 0 && item.isdown === 0) {
            this.schArts = [...this.schArts, item]
          }
        })
      }

      if (this.schArts.length !== 0) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    }
  },
  components: {
    Head: head,
    Foot: Foot

  },
  created () {
    this.getSchArts()
  }
}
</script>

<style lang="less" scped>
*{
    margin: 0;
    padding: 0;
}
li{
    list-style: none;
}
.schArt{

 .el-empty{
  height: 85vh;
  }

.main{
  width: 80%;
  margin: 30px auto;

.queInfo{
    overflow-x: hidden;
    background-color:#fff ;
    border-bottom: 1px solid #dbdbdf;
    cursor: pointer;
    .top{
        display: flex;
        justify-content: space-between;
        padding: 20px 25px;
        .title{
            display: flex;
            align-items: center;
            p{
                margin: 0 20px;
                color: #FF3535;
                font-size: 24px;
                span{

                }
            }
            h3{
                span{
                    color: #d47171;
                }
            }
        }
        .data{
            min-width: 142px;
            text-align: right;
            height: 24px;
            line-height: 24px;
        }
    }
   .content{
         padding: 5px 25px;
        //  overflow: hidden;
         height: 60px;
         text-overflow: ellipsis;
         white-space: nowrap;
         p{
          // width: 430px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
           /* 强制不换行 */
          white-space: nowrap;
          /* 文字用省略号代替超出的部分 */
          text-overflow: ellipsis;
          /* 匀速溢出内容，隐藏 */
          overflow: hidden;
          line-height: 30px;
          -webkit-box-orient: vertical;
          text-indent: 12px;
         }
    }
    .info{
        display: flex;
        padding: 15px 20px;
        justify-content: space-between;
        .views{
            ul{
                display: flex;
                 li{
                    margin: 0 5px;
                     i{
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        vertical-align: bottom;
                        margin-right: 8px;
                        background-size: 16px!important;
                    }
                    .view-icon{
                        background: url('../../../public/images/浏览.png') no-repeat;
                    }
                    .like-icon{
                        background: url('../../../public/images/点赞.png');
                    }
                    .comment-icon{
                        background: url('../../../public/images/消息.png');
                    }
                }
            }
        }
        .edit{
            ul{
                display: flex;
                li{
                    margin: 0 5px;
                    cursor: pointer;
                }
               li:hover{

                   color: #ff3535;
               }
            }
        }
    }
}
.queInfo:hover{
  background-color: #f3f3f3;
}

}
.page{
  width: 32%;
  margin:.5rem auto;
}
}

</style>
